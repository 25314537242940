import React, { useEffect, useState } from "react";
import { Route, Routes, Outlet, useParams } from "react-router-dom";
import { HomeDashboardLayout } from "../../Layout";
import sidebarMenus from "../../constants/sidebarMenus";
import { clearStorage, getStorage } from "../../utils/storage";
import CenterScreen from "./centers/CenterScreen";
import DonorProfileScreen from "../User/DonorProfileScreen";
import CenterSiteScreen from "./CenterSites/CenterSiteScreen";
import DonorScreen from "./Donors/DonorScreen";
import ClubScreen from "./Clubs/ClubScreen";
import PreAssessmentQuestionnaireScreen from "../common/Donor/PreAssessmentQuestionnaireScreen";
import DonorsScreen from "./Donors/DonorsScreen";
import DonationScreen from "./Donations/DonationScreen";
import TestingQuestionnaireScreen from "./Testing/TestingQuestionnaireScreen";
import InventoriesScreen from "./Inventory";
import InventoryScreen from "./Inventory/InventoryScreen";
import InventoriesByStatusScreen from "./Inventory/InventoriesByStatusScreen";
import InventoriesByReasonScreen from "./Inventory/InventoriesByReasonScreen";
import DonorsBloodGroupScreen from "./Donors/DonorsBloodGroupScreen";
import RequestInformationScreen from "./Requests/RequestInformationScreen";
import BloodSeparationsScreen from "./Production/ComponentSeparation/BloodSeparationsScreen";
import BloodTransformationScreen from "./Production/ComponentTransformation/BloodTransformationScreen";
import ComponentLabelingScreen from "./Production/ComponentLabeling/ComponentLabelingScreen";
import HospitalReceptionScreen from "./Requests/HospitalReceptionScreen";
import { useLocation } from "react-router-dom";
import { Modal } from "../../components/Modal";
import PINLogin from "../../components/AdminLogin/PINLogin";
import CenterInventoryScreen from "./Inventory/CenterInventoryScreen";
import { jwtDecode } from "jwt-decode";
import TestingByStatusScreen from "./Testing/TestingByStatusScreen";
import InventoriesByStatusByComponentScreen from "./Inventory/InventoriesByStatusByComponentScreen";
import PatientInfoScreen from "./Patient/PatientInfoScreen";
import BulkRequestInformationScreen from "./Requests/BulkRequestInformationScreen";
import SampleCollectionScreen from "./Requests/SampleCollectionScreen";
import DonorReportScreen from "./Reports/DonorReportScreen";
import DonationReportScreen from "./Reports/DonationReportScreen";

const HomeDashboardScreen = (props) => {
  const location = useLocation();
  const [showPinModal, setOpenModal] = useState(false);

  let sMenus = [];

  if (sidebarMenus(props.defaultLanguage)) {
    sMenus = [...sidebarMenus(props.defaultLanguage)];
  }

  const routes = [
    ...sMenus,
    {
      parentPath: "/dashboard",
      path: "/centers/:centerName/:centerId",
      element: <CenterScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors",
      element: <DonorsScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors/:donorId",
      element: <DonorScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors/:donorId/:donorName",
      element: <DonorScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors/questionnaire",
      element: <PreAssessmentQuestionnaireScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors/questionnaire/:donorId/:donorName",
      element: <PreAssessmentQuestionnaireScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors/questionnaire/:donorId/:donorName/:preassessmentId",
      element: <PreAssessmentQuestionnaireScreen route="/dashboard" />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors/questionnaire/:donorId/:donorNumber/:donorName/:preassessmentId/:requestDate",
      element: <PreAssessmentQuestionnaireScreen route="/dashboard" />,
    },
    {
      parentPath: "/dashboard",
      path: "/donations/:donationId",
      element: <DonationScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donations/:donationId/:donorId",
      element: <DonationScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donations/:donationId/:donorId/:donationNumber",
      element: <DonationScreen />,
    },
    // {
    //   parentPath: "/dashboard",
    //   path: "/testing/:donationId",
    //   element: <TestingQuestionnaireScreen />,
    // },
    // {
    //   parentPath: "/dashboard",
    //   path: "/testing/:donationId/:donorId",
    //   element: <TestingQuestionnaireScreen />,
    // },
    // {
    //   parentPath: "/dashboard",
    //   path: "/testing/:donationId/:donorId/:donorName",
    //   element: <TestingQuestionnaireScreen />,
    // },
    {
      parentPath: "/dashboard",
      path: "/testing/:testId",
      element: <TestingQuestionnaireScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/testing/:testId/:donationNumber",
      element: <TestingQuestionnaireScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/bloodTesting/:status",
      element: <TestingByStatusScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory",
      element: <InventoriesScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory/byStatus/:status",
      element: <InventoriesByStatusScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory/byStatus/:startDate/:endDate/:status",
      element: <InventoriesByStatusScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory/byStatus/:status/:componentName/:component",
      element: <InventoriesByStatusByComponentScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory/byStatus/:startDate/:endDate/:status/:componentName/:component",
      element: <InventoriesByStatusByComponentScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory/byReason/:reason",
      element: <InventoriesByReasonScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory/:donationId/:donorId",
      element: <InventoriesScreen showCounts />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/inventory/:donationId/:donorId/:donorName",
      element: <InventoriesScreen showCounts />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/view-inventory/:inventoryId",
      element: <InventoryScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/view-inventory/:inventoryId/:donationId",
      element: <InventoryScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/donors/bloodGroup/:bloodGroup",
      element: <DonorsBloodGroupScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/request/:requestNo",
      element: <RequestInformationScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/request/bulk/:requestNo",
      element: <BulkRequestInformationScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/request/collect_sample/:requestNo/:requestId",
      element: <SampleCollectionScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/component-separation/:inventoryId/:donationId",
      element: <BloodSeparationsScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/component-transformation/:inventoryId/:donationId",
      element: <BloodTransformationScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/component-labeling/:inventoryId/:donationId",
      element: <ComponentLabelingScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/request&dispatch/hospital/receive/:requestId",
      element: <HospitalReceptionScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/production/center/:centerId/:centerName/:centerSiteId/:centerSiteName",
      element: <CenterInventoryScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/patients/:patientId/:idNumber",
      element: <PatientInfoScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/reports/donors age group",
      element: <DonorReportScreen />,
    },
    {
      parentPath: "/dashboard",
      path: "/reports/blood collection",
      element: <DonationReportScreen />,
    },
  ];

  const isPinValid = async () => {
    const pinValid = await getStorage("PIN");
    const userLoggedIn = await getStorage();
    const newRoute = location.pathname;

    if (userLoggedIn.role === "superAdmin") return true;

    return pinValid?.isValid && pinValid?.route === newRoute;
  };

  useEffect(() => {
    async function userInfo() {
      const user = await getStorage();
      const checkPin = await isPinValid();

      if (!user.token) return (window.location.href = "/login");

      // if (!checkPin) return setOpenModal(true);

      const decodeToken = jwtDecode(user.token);
      const now = new Date().getTime();

      if (decodeToken.exp > now) {
        clearStorage();
        window.location.href = "/admin-login";
      }
    }

    userInfo();
  }, [location]);

  useEffect(() => {
    async function userInfo() {
      const user = await getStorage();

      if (!user.token) return (window.location.href = "/login");
    }

    userInfo();
  }, []); // Adding an empty dependency array to avoid rerunning the effect

  return (
    <>
      <HomeDashboardLayout
        {...useParams()}
        menus={sidebarMenus(props.defaultLanguage)}
      >
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}

          <Route path="/donor/:id" element={<DonorProfileScreen />} />
          <Route
            path="/center-sites/:centerName/:centerId/:siteName/:siteId"
            element={<CenterSiteScreen />}
          />
          <Route path="/clubs/:clubName/:clubId" element={<ClubScreen />} />
        </Routes>
        <Outlet />
      </HomeDashboardLayout>
      <Modal
        show={showPinModal}
        handleClose={() => setOpenModal(false)}
        backdrop="static"
      >
        <PINLogin
          newRoute={location.pathname}
          handleClose={() => setOpenModal(false)}
        />
      </Modal>
    </>
  );
};

export default HomeDashboardScreen;
