import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
import MyRequests from "../../../components/Requests/MyRequests";
import { DashboardHeader } from "../../../components/Header";
import accessMenu from "../../../utils/accessMenu";
import ReceptionSingle from "../../../components/Requests/ReceptionSingle";
import ReceptionBulk from "../../../components/Requests/ReceptionBulk";
import ReturnRequest from "../../../components/Requests/Return";
import { Modal } from "../../../components/Modal";
import NominativeRequest from "../../../components/Requests/nominative/NominativeRequest";
import BulkRequest from "../../../components/Requests/bulk/BulkRequest";
import requestQuery from "../../../utils/queries/requestQuery";
import { Button } from "../../../components/Button";
import CheckboxQuestionnaire from "../../../components/Input/CheckboxQuestionnaire";

class RequestManagementScreen extends React.Component {
  state = {
    user: {},
    data: [],
    type: "bulk",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(
        defaultLanguage,
        await this.returnFilters(search)
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    let tabOptions = [];
    let { canReadBloodRequestAccess } = accessMenu();

    if (canReadBloodRequestAccess) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].reception,
        data: (
          <div className="card">
            <div className="card-body">
              <div className="col-md-6">
                <CheckboxQuestionnaire
                  answers={[
                    {
                      key: "type",
                      value: "bulk",
                      onPress: () => this.handleCheck("type", "bulk"),
                    },
                    {
                      key: "type",
                      value: "product",
                      onPress: () => this.handleCheck("type", "product"),
                    },
                  ]}
                  checked={this.state.type}
                />
                {this.state.type === "bulk" && <ReceptionBulk />}
                {this.state.type === "product" && <ReceptionSingle />}
              </div>
            </div>
          </div>
        ),
      });
    }

    if (canReadBloodRequestAccess) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].return,
        data: (
          <div className="card">
            <div className="card-body">
              <div className="col-md-6">
                <ReturnRequest />
              </div>
            </div>
          </div>
        ),
      });
    }

    if (canReadBloodRequestAccess) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].requests,
        data: <MyRequests showAdd />,
      });
    }

    return (
      <>
        <div className="card-dashboard-content">
          <div className="card-body">
            <div className="d-flex gap-2 mt-2">
              <Button
                text={language[this.props.defaultLanguage].bulk_request}
                className="btn btn-primary"
                onPress={() => this.handleShowModal("showBulkRequest")}
              />

              <Button
                text={language[this.props.defaultLanguage].nominative_request}
                className=" btn-transparent btn-bordered primary"
                onPress={() => this.handleShowModal("showNominativeModal")}
              />
            </div>
            <hr />
            <div>
              <DashboardHeader
                title={
                  language[this.props.defaultLanguage].requests_and_reception
                }
              />
            </div>
            <Tabs options={tabOptions} />
          </div>
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showNominativeModal")}
          show={this.state.showNominativeModal}
          title={language[this.props.defaultLanguage].nominative_request}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <div>
            <NominativeRequest
              getData={this.getData.bind(this)}
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showNominativeModal"
              )}
              {...this.state.selected_data}
            />
          </div>
        </Modal>

        <Modal
          handleClose={() => this.handleCloseModal("showBulkRequest")}
          show={this.state.showBulkRequest}
          title={language[this.props.defaultLanguage].bulk_request}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <div>
            <BulkRequest
              getData={this.getData.bind(this)}
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showBulkRequest"
              )}
              {...this.state.selected_data}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(RequestManagementScreen));
