import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import hospitalQuery from "../../utils/queries/hospitalQuery";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import validateEmail from "../../utils/validateEmail";
import { defineRole } from "../../utils/handleAccessRoles";
import { ROLE_BLOOD_BANK } from "../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewPatient extends React.Component {
  state = {
    requesterName: "",
    requesterPosition: "",
    error: {},
    isSubmitting: false,
    hospitals: [],
    centers: [],
    code: "",
    name: "",
    email: "",
    phoneNumber: "",
    user: {},
    hospitals: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });
    if (this.props._id) {
      this.setState({
        ...this.props,
        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,
        center: this.props.center
          ? {
              label: this.props.center.name,
              value: this.props.center._id,
            }
          : null,
      });
    }
  };

  getHospitals = async (isFetchingHospitals, search = {}) => {
    try {
      this.setState({
        isFetchingHospitals,
      });

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
        ...search,
      });

      this.setState({
        hospitals: data,
        isFetchingHospitals: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingHospitals: false });
    }
  };

  getCenters = async (isFetchingCenters) => {
    try {
      this.setState({
        isFetchingCenters,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        centers: data,
        isFetchingCenters: false,
      });
    } catch (error) {
      this.setState({ isFetchingCenters: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { hospital, email, phoneNumber, error, code, name, center } =
      this.state;

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    // if (!hospital || hospital === "") {
    //   error.hospital = language[this.props.defaultLanguage].hospital_required;
    // }

    // if (!center) {
    //   error.center = language[this.props.defaultLanguage].center_required;
    // }

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    if (email === "") {
      error.email = language[this.props.defaultLanguage].email_required;
    } else if (!validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email;
    }

    if (phoneNumber === "") {
      error.phoneNumber = language[this.props.defaultLanguage].phone_required;
    } else if (phoneNumber.length < 10) {
      error.phoneNumber =
        language[this.props.defaultLanguage].invalid_phone_number;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { hospital, email, phoneNumber, code, name, center, isDefault } =
          this.state,
        url = `${API_URL}/bloodBank`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        hospital: hospital?.value,
        code,
        name,
        email,
        phoneNumber,
        isDefault,
        // center: center.value,
      };

      if (!requestedBody.hospital) {
        requestedBody.isRemoveHospital = true;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                options={this.state.code}
                placeholder={
                  language[this.props.defaultLanguage].code_placeholder
                }
                label={language[this.props.defaultLanguage].code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].email_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].phone_placeholder
                }
                label={language[this.props.defaultLanguage].phone_number}
                value={this.state.phoneNumber}
                onChange={(e) => this.onChangeText("phoneNumber", e)}
                error={this.state.error.phoneNumber}
                required
              />
            </div>
            {/* <div className="col-md-12">
              <Checkbox
                label={language[this.props.defaultLanguage].main_stock}
                checked={this.state.isDefault}
                onCheck={() =>
                  this.setState({
                    isDefault: !this.state.isDefault,
                  })
                }
              />
            </div> */}
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getHospitals(true, { q: e })}
                leftIcon={icons.hospital}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].entity_or_hospital}
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isFetchingHospitals}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          {defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_BLOOD_BANK,
            operation: "create",
          }) && (
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewPatient);
